import { useGettext } from "vue3-gettext";

export function dynStr(
  translations: Array<{ languages_code: string | undefined }>,
  key: string
): string {
  const gettext = useGettext();
  for (const lang of [gettext.current, "en"]) {
    for (const trans of translations) {
      if (trans.languages_code === lang) {
        const value = trans[key];
        if (typeof value === "string") {
          return value;
        }
      }
    }
  }
  return "";
}

export default { dynStr };
