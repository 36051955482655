<template>
  <!--begin::MyOrders-->
  <div class="row gy-5 gx-xl-8">
    <div class="col-xxl-12">
      <TableWidget13
        widget-classes="card-xxl-stretch mb-5 mb-xl-8"
      ></TableWidget13>
    </div>
  </div>
  <!--end::MyOrders-->
</template>

<script lang="ts">
import { useStore } from "vuex";
import { getToken, ID_TOKEN_KEY } from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted } from "vue";
import TableWidget13 from "@/components/widgets/tables/Widget13.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

interface Wrap {
  label: string;
  color: string;
}
interface Datetime {
  date: string;
  time: string;
  timeRemaining: string;
  color: string;
}
interface Output {
  packets: Array<{
    packetId: number;
    s: number;
    date: number;
    products: Array<{
      name: string;
      count: string;
    }>;
    customer: {
      name: string;
      lastname: string;
      phone: string;
      image: string;
    };
    payments: Array<{
      type: string;
      amount: number;
    }>;
    status: Wrap;
    action: Wrap;
    datetime: Datetime;
  }>;
}

export default defineComponent({
  name: "my-orders",
  components: {
    TableWidget13,
  },
  setup() {
    const store = useStore();
    const token = getToken(ID_TOKEN_KEY);
    store.dispatch(Actions.LOGIN_TOKEN_CHANGED, token);
    onMounted(() => {
      setCurrentPageTitle("My orders");
    });
  },
});
</script>
